import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import Login from "./components/Login";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import UploadExcel from "./components/UploadExcel";
import VideoUpload from "./components/VideoUpload";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/video-upload" element={<VideoUpload />} />
        <Route path="/upload-excel" element={<UploadExcel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
