import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div>
      <nav className="nav">
        <img src="./assets/cms-logo.png" className="w-[fit] h-[6vh]" alt="" />

        <div>
          <ul className="nav-links">
            <li>
              <Link to="/home">User Upload</Link>
            </li>
            <li>
              <Link to="#">MIS</Link>
            </li>
            <li>
              <Link to="/" onClick={() => navigate("/")}>Logout</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
