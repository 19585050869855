import React, { useState } from "react";
import Header from "./Header";
import { IoSearchOutline } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Popup from "./Popup";

export default function HomePage() {
  const [newContent, setNewContent] = useState(true);
  const [approvedContent, setApprovedContent] = useState(false);
  const [disapprovedContent, setDisapprovedContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionType, setActionType] = useState("");
  const [moreClick, setMoreClick] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [data, setData] = useState([
    {
      no: 1,
      userName: "John Doe",
      trackActor: "Actor 1",
      trackName: "Track 1",
      albumName: "Album 1",
      releaseDate: "2024-01-01",
      uploadedOn: "2024-01-02",
      distribution: "Online",
      explicit: "No",
      status: "new",
    },
    {
      no: 2,
      userName: "Jane Smith",
      trackActor: "Actor 2",
      trackName: "Track 2",
      albumName: "Album 2",
      releaseDate: "2024-02-01",
      uploadedOn: "2024-02-02",
      distribution: "Physical",
      explicit: "Yes",
      status: "new",
    },
    {
      no: 3,
      userName: "Jane ",
      trackActor: "Actor 3",
      trackName: "Track 3",
      albumName: "Album 3",
      releaseDate: "2024-02-06",
      uploadedOn: "2024-02-07",
      distribution: "Physical",
      explicit: "Yes",
      status: "new",
    },
  ]);

  const filteredData = data.filter((item) => {
    const matchesSearch =
      item.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.trackName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.trackActor.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.explicit.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.albumName.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesSearch;
  });

  const handleNewClick = () => {
    setNewContent(true);
    setApprovedContent(false);
    setDisapprovedContent(false);
  };

  const handleApprovedClick = () => {
    setNewContent(false);
    setApprovedContent(true);
    setDisapprovedContent(false);
  };

  const handleDisapprovedClick = () => {
    setNewContent(false);
    setApprovedContent(false);
    setDisapprovedContent(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleActionClick = (item, type) => {
    setSelectedRow(item);
    setActionType(type);
    setShowConfirm(true);
  };

  const handleDisapproveClick = (item, type) => {
    setSelectedRow(item);
    setActionType(type);
    setShowConfirm(true);
  };

  const handleMoreClick = () => {
    setMoreClick(!moreClick);
  };

  const handleEditClick = () => {
    setShowComponent(!showComponent);
  };

  const handlePreviewClick = () => {
    setShowPreview(!showPreview);
  };

  const handleConfirm = () => {
    if (actionType === "approve") {
      setData((prevData) =>
        prevData.map((item) =>
          item.no === selectedRow.no ? { ...item, status: "approved" } : item
        )
      );
      toast.success("Audio content approved and data ingested in metasea.");
    } else if (actionType === "disapprove") {
      setData((prevData) =>
        prevData.map((item) =>
          item.no === selectedRow.no ? { ...item, status: "disapproved" } : item
        )
      );
    }
    setShowConfirm(false);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const renderTable = (status) => (
    <div className="scrollable-table ml-[5vw] w-[90vw] rounded-md">
      <table className="info w-full">
        <thead className="bg-[#252525] pb-4">
          <tr>
            <th className="autnh-1 rounded-l-md">No.</th>
            <th className="autnh-1">User Name</th>
            <th className="autnh-1">Track Actor</th>
            <th className="autnh-1">Track Name</th>
            <th className="autnh-1">Album Name</th>
            <th className="autnh-1">Release Date</th>
            <th className="autnh-1">Uploaded On</th>
            <th className="autnh-1">Distribution</th>
            <th className="autnh-1">Artwork</th>
            <th className="autnh-1">Edit</th>
            <th className="autnh-1">Explicit</th>
            <th className="autnh-1 rounded-r-md bg-[#3B3B3B]">
              Actions to be performed
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="h-2 bg-transparent"></tr>
        </tbody>
        <tbody className="mt-[10%]">
          {filteredData
            .filter((item) => item.status === status)
            .map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="autnr rounded-l-md">{index + 1}</td>
                  <td className="autnr">{item.userName}</td>
                  <td className="autnr">{item.trackActor}</td>
                  <td className="autnr">{item.trackName}</td>
                  <td className="autnr">{item.albumName}</td>
                  <td className="autnr">{item.releaseDate}</td>
                  <td className="autnr">{item.uploadedOn}</td>
                  <td className="autnr">{item.distribution}</td>
                  <td className="autnr">
                    <button
                      className="text-blue-400"
                      onClick={handlePreviewClick}
                    >
                      View
                    </button>
                  </td>
                  <td className="autnr">
                    <button className="text-blue-400" onClick={handleMoreClick}>
                      More
                    </button>
                  </td>
                  <td className="autnr">{item.explicit}</td>
                  <td className="bg-[#252525] h-[100%] flex justify-around p-[10px] rounded-r-md">
                    {item.status === "new" && (
                      <>
                        <button className="bg-[white] rounded-3xl ">
                          <FaPlay
                            style={{
                              color: "black",
                              fontSize: "1.4rem",
                              padding: "6px",
                            }}
                          />
                        </button>
                        <button
                          className="bg-[#6CC72B] rounded-3xl"
                          onClick={() => handleActionClick(item, "approve")}
                        >
                          <TiTick
                            style={{ color: "black", fontSize: "1.4rem" }}
                          />
                        </button>
                        <button
                          className="bg-[#FF2947] rounded-3xl"
                          onClick={() =>
                            handleDisapproveClick(item, "disapprove")
                          }
                        >
                          <ImCross
                            style={{
                              color: "black",
                              fontSize: "1.4rem",
                              padding: "6px",
                            }}
                          />
                        </button>
                        <button className="bg-[#FFB900] rounded-3xl">
                          <IoMdDownload
                            style={{
                              color: "black",
                              fontSize: "1.4rem",
                              padding: "4px",
                            }}
                          />
                        </button>
                        <button className="">
                          <RiDeleteBin6Line
                            style={{ color: "white", fontSize: "1.4rem" }}
                          />
                        </button>
                      </>
                    )}
                    {item.status === "approved" && (
                      <button className="bg-[white] rounded-3xl ">
                        <FaPlay
                          style={{
                            color: "black",
                            fontSize: "1.4rem",
                            padding: "6px",
                          }}
                        />
                      </button>
                    )}
                    {item.status === "disapproved" && (
                      <>
                        <button className="bg-[white] rounded-3xl ">
                          <FaPlay
                            style={{
                              color: "black",
                              fontSize: "1.4rem",
                              padding: "6px",
                            }}
                          />
                        </button>
                        <button
                          className="bg-[#6CC72B] rounded-3xl"
                          onClick={() => handleActionClick(item, "approve")}
                        >
                          <TiTick
                            style={{ color: "black", fontSize: "1.4rem" }}
                          />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
                <tr className="gap-row">
                  <td colSpan="2"></td>{" "}
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh] w-[90vw]">
        <div className="flex items-center justify-center w-[25vw] ml-[3vw]">
          <img
            src="./assets/aa_logo.png"
            className="w-[auto] h-[22vh] p-[5px] rotate-logo"
            alt=""
          />
        </div>
        <div className="flex flex-col ml-[5vw]">
          <div className="flex items-center bg-[#1E1E1E] w-[70vw] h-[8vh] rounded-md">
            <Link to="/home" className="text-[#FEC961] text-[16px] ml-[5vw]">
              Audio Upload
            </Link>
            <Link to="/video-upload" className="text-white text-[16px] ml-[5vw]">
              Video Upload
            </Link>
            <Link
              to="/upload-excel"
              className="text-white text-[16px] ml-[5vw]"
            >
              Upload Excel
            </Link>
          </div>
          <br />
          <div className="flex bg-[#1E1E1E] w-[auto] h-[15vh] rounded-md text-white">
            <div
              className="flex items-center justify-center w-[40%] rounded-md text-[1.7rem] text-[#FF6C2F]"
              style={{ fontWeight: "normal" }}
            >
              <h2>User Audio Upload</h2>
            </div>
            <div className="flex flex-col bg-[#252525] w-[60%] h-[15vh] rounded-md ">
              <div className="flex items-center h-[50%] p-[10px] pl-[38px]">
                <h2 className="metasea-info">
                  Code available for Metasea Ingestion{" "}
                </h2>
              </div>
              <div className="flex items-center justify-around w-[100%]">
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">ISRC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">1732</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">UPC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">1043</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center bg-[#161616] p-[10px] rounded-md">
                    <h2 className="metasea-info">CATALOGUE</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">1073</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex ml-[5vw] w-[90vw] h-[7vh] text-[0.8rem] text-white mb-[1.5%]">
        <button
          className={`w-[17%] flex items-center justify-center ${
            newContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          style={{ borderRadius: "10px 0px 0px 10px" }}
          onClick={handleNewClick}
        >
          New
        </button>
        <button
          className={`w-[17%] ml-[0.5%] flex items-center justify-center ${
            approvedContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          onClick={handleApprovedClick}
        >
          Approved
        </button>
        <button
          className={`w-[17%] ml-[0.5%] flex items-center justify-center ${
            disapprovedContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          style={{ borderRadius: "0px 10px 10px 0px" }}
          onClick={handleDisapprovedClick}
        >
          Disapproved
        </button>
        <div className="w-[48%] ml-[0.5%] relative">
          <input
            type="search"
            name="searchBar"
            id=""
            className="bg-[#252525] text-white rounded-md outline-none p-[10px] pl-[50px] w-full h-[7vh]"
            placeholder="Search.."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="absolute top-[50%] left-[15px] transform -translate-y-[50%]">
            <IoSearchOutline className="text-white" />
          </div>
        </div>
      </div>
      <div className="mt-[2vh]">{newContent && renderTable("new")}</div>
      <div className="mt-[2vh]">
        {approvedContent && renderTable("approved")}
      </div>
      <div className="mt-[2vh]">
        {disapprovedContent && renderTable("disapproved")}
      </div>
      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-40">
          <div className="bg-[#171717] p-6 rounded-md">
            <h2 className="text-xl mb-4 text-white">Are you sure?</h2>
            <div className="flex justify-around">
              <button
                className="bg-[#FF6C2F] text-white px-4 py-2 rounded-md hover:bg-[#FF5733] active:bg-[#c75525]"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="bg-[#3B3B3B] text-white px-4 py-2 rounded-md hover:bg-[#484848] active:bg-[#484848]"
                onClick={handleCancel}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {showPreview && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-70">
          <div className="relative bg-[#555555] p-4 rounded-md w-[50vw] max-w-screen-lg h-[50vh] overflow-x-auto flex justify-center items-center">
            <div className="">
              <button
              className="absolute top-0 right-0 mt-2 mr-2 bg-transparent hover:bg-[#363636] active:bg-[#535353] text-white w-8 h-8 rounded-full flex items-center justify-center"
              onClick={() => setShowPreview(false)}
            >
              X
            </button>
              <img
                src="./assets/cms-logo.png"
                alt=""
                className="w-[fit] h-[fit]"
              />
            </div>
          </div>
        </div>
      )}
      {moreClick && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-90">
          <div className="bg-[#555555] p-4 rounded-md w-full max-w-screen-lg h-[40vh] overflow-x-auto">
            <button
              className="absolute top-2 right-2 bg-transparent hover:bg-[#363636] active:bg-[#535353] text-white w-8 h-8 rounded-full flex items-center justify-center"
              onClick={() => setMoreClick(false)}
            >
              X
            </button>
            <table className="info w-full rounded-md">
              <thead className="bg-[#252525] pb-4">
                <tr>
                  <th className="autnh-1 rounded-l-md border-r border-gray-600">
                    LANGUAGE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">GENRE</th>
                  <th className="autnh-1 border-r border-gray-600">
                    SUB GENRE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">MOOD</th>
                  <th className="autnh-1 border-r border-gray-600">LYRICIST</th>
                  <th className="autnh-1 border-r border-gray-600">
                    MUSIC DIRECTOR
                  </th>
                  <th className="autnh-1 border-r border-gray-600">SINGER</th>
                  <th className="autnh-1 border-r border-gray-600">PRODUCER</th>
                  <th className="autnh-1 border-r border-gray-600">
                    DESCRIPTION
                  </th>
                  <th className="autnh-1 border-r border-gray-600">TAGS</th>
                  <th className="autnh-1 border-r border-gray-600">OTHERS</th>
                  <th className="autnh-1 border-r border-gray-600">
                    COPY RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">
                    PUBLISHING RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">KEYWORDS</th>
                  <th className="autnh-1 border-r border-gray-600">
                    TRACK NAME
                  </th>
                  <th className="autnh-1 rounded-r-md ">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-0.5 bg-transparent"></tr>
              </tbody>
              <tbody>
                <tr>
                  <td className="autnr rounded-l-md border-r border-gray-600">
                    test
                  </td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr rounded-r-md">
                    <button className="text-blue-500" onClick={handleEditClick}>
                      Edit
                    </button>
                    {showComponent && <Popup />}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
